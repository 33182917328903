
import React from 'react';

import './AfterSchoolCalendarItem.css';

class AfterSchoolCalendarItem extends React.Component{

  props = null;

  constructor(props){
    super();

    this.props = props;
  }

  render(){
    return (
      <div className={(this.props.classTitle == null ? "calendardiv_hidden" : (this.props.noheader != null ? "calendardiv_small" : "calendardiv"))}>
        <div className={ this.props.noheader != null ? "noheader" : "header"}>
          {this.props.dayOfWeek}
        </div>
        <div className="title">
          {this.props.classTitle}
        </div>
        <div className="time">
          {this.props.time}
        </div>
        <div className="other">
          {this.props.cost}
        </div>
        <div className="other">
          Grades: {this.props.grades}
        </div>
        <div className="other">
          {this.props.room}
        </div>
      </div>
    );
  }
}

export default AfterSchoolCalendarItem;