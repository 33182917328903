import React from 'react';

import "./AfterSchoolCalendar.css"
import AfterSchoolCalendarItem from "./AfterSchoolCalendarItem";

class AfterSchoolCalendar extends React.Component{

  classInfo = null;
  props = null;

  constructor(props){
    super();

    this.props = props;
    this.classInfo = props.sessionInfo.classInfo;
  }

  gotoSignupForm( url ){
    window.open(url, "_blank");
  }

  render(){
    return (

      <div className="mainframe">
        <div className="sessionTitle">
          {this.props.sessionInfo.sessionTitle}
        </div>
        <div className="sessionDates">
          {this.props.sessionInfo.sessionDates}
        </div>

          {/*<div className="sessionDates">*/}
          {/*    <a href="https://forms.gle/3pgbGv8yPB8q6w2K6" target="_blank">Special Session 3/12 & 3/13</a>*/}
          {/*</div>*/}

        <div className="titlebar bold">
          All Participants must have a signed parent contract (now available during online registration)
        </div>

        <div className="titlebar">
          All classes must have a minimum enrollment to run.  Class sizes are limited.   You will be notified if your class doesn't meet the minimum enrollment and you will then choose a different class or request a refund.
        </div>

        <div>
          <button className="signupbutton" onClick={() => this.gotoSignupForm(this.props.sessionInfo.sessionURL)}>Sign Up Now</button>
        </div>

        <div className="calendarcontainer">
          <div className="calendarrow">
            <AfterSchoolCalendarItem dayOfWeek={this.classInfo[0][0].dayOfWeek} classTitle={this.classInfo[0][0].classTitle} time={this.classInfo[0][0].time} cost={this.classInfo[0][0].cost} grades={this.classInfo[0][0].grades} room={this.classInfo[0][0].room}/>
            <AfterSchoolCalendarItem dayOfWeek={this.classInfo[0][1].dayOfWeek} classTitle={this.classInfo[0][1].classTitle} time={this.classInfo[0][1].time} cost={this.classInfo[0][1].cost} grades={this.classInfo[0][1].grades} room={this.classInfo[0][1].room}/>
            <AfterSchoolCalendarItem dayOfWeek={this.classInfo[0][2].dayOfWeek} classTitle={this.classInfo[0][2].classTitle} time={this.classInfo[0][2].time} cost={this.classInfo[0][2].cost} grades={this.classInfo[0][2].grades} room={this.classInfo[0][2].room}/>
            <AfterSchoolCalendarItem dayOfWeek={this.classInfo[0][3].dayOfWeek} classTitle={this.classInfo[0][3].classTitle} time={this.classInfo[0][3].time} cost={this.classInfo[0][3].cost} grades={this.classInfo[0][3].grades} room={this.classInfo[0][3].room}/>
            <AfterSchoolCalendarItem dayOfWeek={this.classInfo[0][4].dayOfWeek} classTitle={this.classInfo[0][4].classTitle} time={this.classInfo[0][4].time} cost={this.classInfo[0][4].cost} grades={this.classInfo[0][4].grades} room={this.classInfo[0][4].room}/>
          </div>

          <div className="calendarrow_noheader">
            <AfterSchoolCalendarItem noheader dayOfWeek={this.classInfo[1][0].dayOfWeek} classTitle={this.classInfo[1][0].classTitle} time={this.classInfo[1][0].time} cost={this.classInfo[1][0].cost} grades={this.classInfo[1][0].grades} room={this.classInfo[1][0].room}/>
            <AfterSchoolCalendarItem noheader dayOfWeek={this.classInfo[1][1].dayOfWeek} classTitle={this.classInfo[1][1].classTitle} time={this.classInfo[1][1].time} cost={this.classInfo[1][1].cost} grades={this.classInfo[1][1].grades} room={this.classInfo[1][1].room}/>
            <AfterSchoolCalendarItem noheader dayOfWeek={this.classInfo[1][2].dayOfWeek} classTitle={this.classInfo[1][2].classTitle} time={this.classInfo[1][2].time} cost={this.classInfo[1][2].cost} grades={this.classInfo[1][2].grades} room={this.classInfo[1][2].room}/>
            <AfterSchoolCalendarItem noheader dayOfWeek={this.classInfo[1][3].dayOfWeek} classTitle={this.classInfo[1][3].classTitle} time={this.classInfo[1][3].time} cost={this.classInfo[1][3].cost} grades={this.classInfo[1][3].grades} room={this.classInfo[1][3].room}/>
            <AfterSchoolCalendarItem noheader dayOfWeek={this.classInfo[1][4].dayOfWeek} classTitle={this.classInfo[1][4].classTitle} time={this.classInfo[1][4].time} cost={this.classInfo[1][4].cost} grades={this.classInfo[1][4].grades} room={this.classInfo[1][4].room}/>
          </div>
        </div>
      </div>
    );
  }
}

export default AfterSchoolCalendar;