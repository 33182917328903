import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AfterSchoolCalendar from './AfterSchoolCalendar';
import * as serviceWorker from './serviceWorker';

let classInf = [[
    {
        dayOfWeek: "Monday",
        classTitle: "Flag Football",
        time: "2:05pm - 4pm",
        cost: "$90",
        grades: "K-5",
        room: "Playground"
    },
    {
        dayOfWeek: "Tuesday",
        classTitle: "Basketball",
        time: "2:05pm - 4pm",
        cost: "$90",
        grades: "1-5",
        room: "Playground"
    },
    {
        dayOfWeek: "Wednesday",
        classTitle: "Dance/Hip Hop",
        time: "2:05pm - 4pm",
        cost: "$108",
        grades: "K-5",
        room: "Multi-Purpose Room"
    },
    {
        dayOfWeek: "Thursday",
        classTitle: "Art",
        time: "12:05pm - 2pm",
        cost: "$123",
        grades: "1-5",
        room: "Room 304"
    },
    {
        dayOfWeek: "Friday",
        classTitle: "Kid's Fitness",
        time: "2:05pm - 4pm",
        cost: "$108",
        grades: "K-5",
        room: "Playground"
    },
],
[
    {

    },
    {
        // dayOfWeek: "Tuesday",
        // classTitle: "Beginning Coding",
        // time: "3pm - 4pm",
        // cost: "$60",
        // grades: "4-5",
        // room: "Online"
    },
    {
    //     dayOfWeek: "Wednesday",
    //     classTitle: "Hip Hop",
    //     time: "3pm - 4pm",
    //     cost: "$60",
    //     grades: "K-5",
    //     room: "Online"
    },
    {
        // dayOfWeek: "Thursday",
        // classTitle: "Continued Coding",
        // time: "3pm - 4pm",
        // cost: "$60",
        // grades: "4-5",
        // room: "Online"
    },
    {}
]];

let sessionInfo = {
  sessionTitle: "After School Session #1",
  sessionDates: "September 7th - October 15th 2021",
  sessionURL: "https://forms.gle/esfGzxhqC7L61MhC8",
  classInfo: classInf
}

ReactDOM.render(<AfterSchoolCalendar sessionInfo={sessionInfo}/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
